import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "base",
    component: () => import("../views/base/index.vue"),
    children: [
      {
        path: "/",
        name: "chufa",
        meta: {
          name: "行政处罚",
          nav: true,
          bg:require('../assets/image/left-title-bg2.png'),
          selbg:require('../assets/image/left-title-bg-selected.png')
        },
        component: () => import("../views/business/index.vue"),
      },
      {
        path: "/tousu",
        name: "tousu",
        meta: {
          name: "投诉举报",
          nav: true,
          bg:require('../assets/image/left-title-bg2.png'),
          selbg:require('../assets/image/left-title-bg-selected.png')
        },
        component: () => import("../views/industry/index.vue"),
      },
    
    ],
  },

];

const router = new VueRouter({
  routes,
});

export default router;

import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import router from './router'
import toast from './toast'

Vue.mixin({
    mixins: [router, toast],
    data() {
        return {

        };
    },
    computed: {
        ...mapState(["userInfo", "token"]),
    },
    created() {},
    methods: {
        ...mapMutations(["setUserInfo", "clearUser"]),
        // getUserInfo: function() {
        //     this.$post('User/userInfo', {
        //         token: true,
        //     }).then(res => {
        //         if (res.code == 1) {
        //             res.data.password = '*******'
        //             this.setUserInfo(res.data);
        //             localStorage.setItem('user', JSON.stringify(res.data));
        //         }
        //     }).finally(() => {})
        // }

    },

});
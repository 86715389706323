import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: localStorage["user"] ? JSON.parse(localStorage["user"]) : {},
        token: localStorage["token"] ? localStorage["token"] : ''
    },
    mutations: {
        setUserInfo: function(state, payload) {
            console.log(payload)
            state.userInfo = payload;
            state.token = payload.token
        },
        clearUser: function(state, payload) {
            state.userInfo = {}
        }
    },
    actions: {},
    modules: {},
});
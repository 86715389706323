import Vue from 'vue';
import {  post, get, upfile,javaGet ,javaPost} from './requset';
// import { javaGet } from './requsetJava';
import url from './url'
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$upfile = upfile;
Vue.prototype.$javaget = javaGet;
Vue.prototype.$javapost = javaPost;
Vue.prototype.url = url.url;

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
  }
}
</script>
<style scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-sizing: content-box
}


.line1 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.line2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.line3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}

.input-value-border {
	border: 0 !important;
	text-align: right;
}


@import "./assets/css/base.css";
@import "./assets/css/reset.css";
</style> 